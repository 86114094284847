import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { getFiles, getFilesFailure, getFilesSuccess } from "../actions/files.action";
import { FilesService } from "../../main-app/services/files.service";
import { catchError, exhaustMap, lastValueFrom, map, of, take, tap } from "rxjs";
import { FileMapper } from "../../common/mappers/files.mapper";
import { ProductsService } from "../../main-app/services/products.service";
import { ToastrService } from "ngx-toastr";
import { Store } from "@ngrx/store";
import { getParsedStockFile, getParsedStockFileFailure, getParsedStockFileSuccess } from "../actions/parsed-stock-file.action";
import { getLowStockProducts, getLowStockProductsFailure, getLowStockProductsSuccess } from "../actions/low-stock-products.action";

@Injectable({ providedIn: 'root' })
export class LowStockProductsEffect {

    lowStockProducts$ = createEffect(() => this.actions$.pipe(
        ofType(getLowStockProducts),
        exhaustMap(action => this.productServices.getLowStockProducts(action.searchParameters).pipe(
            map(response => getLowStockProductsSuccess({ 
                lowStockProducts: response
            }))
        )),
        catchError(error => of(getLowStockProductsFailure()))
    ));

    getLowStockProductsFailure$ = createEffect(() => this.actions$.pipe(
        ofType(getLowStockProductsFailure),
        map(() => {
            this.toastr.warning("Hubo un error al cargar los productos con bajo stock, por favor intenta de nuevo");
        })
    ), { dispatch: false });

    constructor(
        private readonly actions$: Actions,
        private readonly productServices: ProductsService,
        private readonly toastr: ToastrService,
        private readonly store: Store
    ) { }

}
