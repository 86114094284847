import {GroupedProduct} from "../../store/states/grouped-products.state";
import { Provider } from "../../store/states/providers.state";
import {ItemDto} from "../api-dtos/responses/products.dto";
import { ProviderDto } from "../api-dtos/responses/provider.dto";
import {ProductsMapper} from "./products.mapper";

export class ProviderMapper {

  static fromApiToState(provider: ProviderDto): Provider {
    return {
      id: provider.id,
      name: provider.name,
    }
  }

}