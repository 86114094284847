import { createReducer, on } from '@ngrx/store';
import { initialParsePriceFilesState } from '../states/parsed-price-file.state';
import { clearParsedPriceFile, getParsedPriceFileSuccess } from '../actions/parsed-price-file.action';

export const pricedParseFileReducer = createReducer(
  initialParsePriceFilesState,
  on(getParsedPriceFileSuccess, (state, { file }) => ({
    ...state, file: file
  })),
  on(clearParsedPriceFile, (state) => ({
      ...state,
      files: initialParsePriceFilesState.file
  })),
);