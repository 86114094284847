import { Injectable } from "@angular/core";
import { SearchParametersDto } from "../../common/api-dtos/requests/search-parameters.dto";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { HttpRequestHelper } from "../../common/helpers/http-request.helper";
import { ProviderDto } from "../../common/api-dtos/responses/provider.dto";

@Injectable({ providedIn: 'root' })
export class ProviderService {
    
    constructor(
        private readonly http: HttpClient
    ) {}

    private readonly BASE_URL = environment.BACKEND_URL || "localhost:3000"
    private readonly PROVIDERS_ENDPOINT = `${this.BASE_URL}/v1/providers`

    getProviders(params: SearchParametersDto) {
        const headers = HttpRequestHelper.getHttpHeaders(params);
        const options = HttpRequestHelper.getHttpOptions(headers, params);
        return this.http.get<ProviderDto[]>(`${this.PROVIDERS_ENDPOINT}`, options);
    }

}