import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { getFiles, getFilesFailure, getFilesSuccess, getTotalPendingDocuments, getTotalPendingDocumentsSuccess } from "../actions/files.action";
import { FilesService } from "../../main-app/services/files.service";
import { catchError, exhaustMap, map, of } from "rxjs";
import { FileMapper } from "../../common/mappers/files.mapper";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class FilesEffect {

    getFiles$ = createEffect(() => this.actions$.pipe(
        ofType(getFiles),
        exhaustMap(action => this.filesService.getFiles(action.searchParameters, action.filters).pipe(
            map(response => getFilesSuccess({ 
                files: response.files.map(file => FileMapper.fromTableDtoToModel(file)),
                totalFiles: response.total
            }))
        )),
        catchError(error => of(getFilesFailure()))
    ));

    getFilesFailure$ = createEffect(() => this.actions$.pipe(
        ofType(getFilesFailure),
        map(() => {
            this.toastService.warning("Hubo un error al cargar los archivos, por favor intenta de nuevo");
        })
    ), { dispatch: false });

    totalPendingDocuments$ = createEffect(() => this.actions$.pipe(
        ofType(getTotalPendingDocuments),
        exhaustMap(() => this.filesService.getTotalPendingDocuments().pipe(
            map(response => getTotalPendingDocumentsSuccess({ totalPendingDocuments: response.totalPendingDocuments }))
        )),
        catchError(error => of(getFilesFailure()))
    ));

    constructor(
        private actions$: Actions,
        private filesService: FilesService,
        private toastService: ToastrService
    ) { }

}
