import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { getFiles, getFilesFailure, getFilesSuccess } from "../actions/files.action";
import { FilesService } from "../../main-app/services/files.service";
import { catchError, exhaustMap, lastValueFrom, map, of, take, tap } from "rxjs";
import { FileMapper } from "../../common/mappers/files.mapper";
import { getParsedPriceFile, getParsedPriceFileSuccess, updateItemPriceByPricedFileId, updateItemPriceByPricedFileIdFailure, updateItemPriceByPricedFileIdSuccess } from "../actions/parsed-price-file.action";
import { ProductsService } from "../../main-app/services/products.service";
import { ToastrService } from "ngx-toastr";
import { Store } from "@ngrx/store";
import { selectParsedPriceFileSelector } from "../states/parsed-price-file.state";

@Injectable({ providedIn: 'root' })
export class ParsedPriceFileEffect {

    parsedPriceFileEffect$ = createEffect(() => this.actions$.pipe(
        ofType(getParsedPriceFile),
        exhaustMap(action => this.filesService.getPriceParseFile(action.id).pipe(
            map(response => getParsedPriceFileSuccess({ 
                file: FileMapper.fromPricedParsedFileToModel(response),
            }))
        )),
        catchError(error => of(getFilesFailure()))
    ));

    parsedPriceFileFailure$ = createEffect(() => this.actions$.pipe(
        ofType(getFilesFailure),
        map(() => {
            this.toastr.warning("Hubo un error al cargar el archivo de precios, por favor intenta de nuevo");
        })
    ), { dispatch: false });

    updateItemByPricedFileId$ = createEffect(() => this.actions$.pipe(
        ofType(updateItemPriceByPricedFileId),
        exhaustMap(action => this.productServices.updatePriceByPricedFileId(action.itemId, action.fileId)
            .pipe(map(_ => updateItemPriceByPricedFileIdSuccess()))),
            catchError(_ => of(updateItemPriceByPricedFileIdFailure()))
    ));

    updateItemPriceByPricedFileIdSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(updateItemPriceByPricedFileIdSuccess),
        exhaustMap(() =>
            this.store.select(selectParsedPriceFileSelector).pipe(
                take(1),
                map(fileState => {
                    const id = fileState.file?.id;
                    if (!id) {
                        return { type: 'NO_ACTION' };
                    }
                    return getParsedPriceFile({ id });
                })
            )
        ),
        tap(() => this.toastr.success("Precio actualizado correctamente"))
    ));

    updateItemByPricedFileIdFailure$ = createEffect(() => this.actions$.pipe(
        ofType(updateItemPriceByPricedFileIdFailure),
        tap(() => this.toastr.error('Fallo al actualizar el precio del item'))
    ), { dispatch: false });

    constructor(
        private readonly actions$: Actions,
        private readonly filesService: FilesService,
        private readonly productServices: ProductsService,
        private readonly toastr: ToastrService,
        private readonly store: Store
    ) { }

}
