import { createReducer, on } from '@ngrx/store';
import {initialGroupedProductsState} from "../states/grouped-products.state";
import {
  clearGroupedProductsList,
  getGroupedProductsSuccess,
} from "../actions/grouped-products.actions";

export const groupedProductsReducer = createReducer(
  initialGroupedProductsState,
  on(
    getGroupedProductsSuccess, (state, { products, totalProducts }) => (
      {
      ...state, 
      groupedProducts: products,
      totalProducts: totalProducts,
      })
  ),
  on(clearGroupedProductsList, (state) => ({
    ...state, groupedProducts: initialGroupedProductsState.groupedProducts
  })),
);
