import { createReducer, on } from '@ngrx/store';
import { initialProvidersState } from '../states/providers.state';
import { cleanProvidersList, getProvidersList, getProvidersListSuccess } from '../actions/providers.action';
import { initialLowStockProducts } from '../states/low-stock-products.state';
import { clearLowStockProducts, getLowStockProductsSuccess } from '../actions/low-stock-products.action';

export const lowStockProductsReducer = createReducer(
  initialLowStockProducts,
  on(getLowStockProductsSuccess, (state, { lowStockProducts }) => ({
    ...state,
    ammountOfLowStockProducts : lowStockProducts.ammountOfLowStockProducts,
    lowStockProducts: lowStockProducts.lowStockProducts
  })),
  on(clearLowStockProducts, (state) => ({
    ...initialLowStockProducts
  })),
);
