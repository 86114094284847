import { createReducer, on } from '@ngrx/store';
import { initialParsePriceFilesState } from '../states/parsed-price-file.state';
import { clearParsedPriceFile, getParsedPriceFileSuccess } from '../actions/parsed-price-file.action';
import { initialParseStockFilesState } from '../states/parsed-stock-file.state';
import { clearParsedStockFile, getParsedStockFile, getParsedStockFileSuccess } from '../actions/parsed-stock-file.action';

export const stockParseFileReducer = createReducer(
  initialParseStockFilesState,
  on(getParsedStockFileSuccess, (state, { file }) => ({
    ...state, file: file
  })),
  on(clearParsedStockFile, (state) => ({
      ...state,
      files: initialParsePriceFilesState.file
  })),
);