import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, exhaustMap, map, switchMap, tap} from 'rxjs/operators';
import {of} from "rxjs";
import { getProvidersList, getProvidersListFailure, getProvidersListSuccess } from "../actions/providers.action";
import { ProviderService } from "../../main-app/services/providers.service";
import { ProviderMapper } from "../../common/mappers/provider.mapper";
import { getSaleOrders, getSaleOrdersFailure, getSaleOrdersSuccess } from "../actions/sale-orders.action";
import { SaleOrderService } from "../../main-app/services/sale-orders.service";
import { SaleOrderMapper } from "../../common/mappers/sale-order.mapper";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class SaleOrdersEffect {

  saleOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSaleOrders),
      exhaustMap(action => {
          return this.saleOrdersService.getTableSaleOrders(action.searchParameters).pipe(
            map(response => {
               return getSaleOrdersSuccess({
                 orders: response.orders.map(order => SaleOrderMapper.fromTableToState(order)),
                 totalOrders: response.totalOrders
               })
            }),
            catchError(error => {
              return of(getSaleOrdersFailure())
            })
          )
        }
      )
    )
  );

  saleOrdersFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSaleOrdersFailure),
      tap(() => {
        this.toastService.warning("Hubo un error al cargar las ordenes de venta, por favor intenta de nuevo");
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private saleOrdersService: SaleOrderService,
    private toastService: ToastrService
  ) {}

}
