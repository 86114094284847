import { createReducer, on } from '@ngrx/store';
import { initialDashboardState } from '../states/dashboard.state';
import { clearHistoricalProductPrices, clearProductsAugmentation, getHistoricalProductPrices, getHistoricalProductPricesFailure, getHistoricalProductPricesSuccess, getProductsAugmentationSuccess, getProvidersAugmentationSuccess } from '../actions/dashboards.action';

export const dashboardReducer = createReducer(
  initialDashboardState,
  on(getProvidersAugmentationSuccess, (state, { providersAugmentation }) => ({
    ...state,
    providerAugmentations: providersAugmentation
  })),
  on(getHistoricalProductPricesSuccess, (state, { historicalProductPrices }) => ({
    ...state,
    historicalPriceMap: historicalProductPrices,
    historicalPriceMapLoading: false,
  })),
  on(clearHistoricalProductPrices, (state) => ({
    ...state,
    historicalPriceMapLoading: false,
    historicalPriceMap: []
  })),
  on(getHistoricalProductPrices, (state) => ({
    ...state,
    historicalPriceMapLoading: true
  })),
  on(getHistoricalProductPricesFailure, (state) => ({
    ...state,
    historicalPriceMapLoading: false
  })),
  on(getProductsAugmentationSuccess, (state, { productsAugmentation }) => ({
    ...state,
    productAugmentations: productsAugmentation
  })),
  on(clearProductsAugmentation, (state) => ({
    ...state,
    productAugmentations: []
  })) 
);
