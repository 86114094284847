import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { getFiles, getFilesFailure, getFilesSuccess } from "../actions/files.action";
import { FilesService } from "../../main-app/services/files.service";
import { catchError, exhaustMap, lastValueFrom, map, of, take, tap } from "rxjs";
import { FileMapper } from "../../common/mappers/files.mapper";
import { getParsedPriceFile, getParsedPriceFileSuccess, updateItemPriceByPricedFileId, updateItemPriceByPricedFileIdFailure, updateItemPriceByPricedFileIdSuccess } from "../actions/parsed-price-file.action";
import { ProductsService } from "../../main-app/services/products.service";
import { ToastrService } from "ngx-toastr";
import { Store } from "@ngrx/store";
import { selectParsedPriceFileSelector } from "../states/parsed-price-file.state";
import { getParsedStockFile, getParsedStockFileFailure, getParsedStockFileSuccess, updateItemStockByParsedFileId, updateItemStockByParsedFileIdFailure, updateItemStockByParsedFileIdSuccess } from "../actions/parsed-stock-file.action";
import { selectParsedStockFileSelector } from "../states/parsed-stock-file.state";

@Injectable({ providedIn: 'root' })
export class ParsedStockFileEffect {

    parsedStockFile$ = createEffect(() => this.actions$.pipe(
        ofType(getParsedStockFile),
        exhaustMap(action => this.filesService.getStockParseFile(action.id).pipe(
            map(response => getParsedStockFileSuccess({ 
                file: FileMapper.fromStockParsedFileToModel(response),
            }))
        )),
        catchError(error => of(getParsedStockFileFailure()))
    ));

    parsedStockFileFailure$ = createEffect(() => this.actions$.pipe(
        ofType(getFilesFailure),
        map(() => {
            this.toastr.warning("Hubo un error al cargar el archivo de stock, por favor intenta de nuevo");
        })
    ), { dispatch: false });

    updateStockByFile$ = createEffect(() =>
        this.actions$.pipe(
          ofType(updateItemStockByParsedFileId),
          exhaustMap(action => 
            this.productServices.updateStockByParsedFileId(action.itemId, action.fileId).pipe(
              map(() => updateItemStockByParsedFileIdSuccess()),
              catchError(() => of(updateItemStockByParsedFileIdFailure()))
            )
          )
        )
    );

    updateStockByFileSuccess$ = createEffect(() =>
        this.actions$.pipe(
          ofType(updateItemStockByParsedFileIdSuccess),
          exhaustMap(() =>
            this.store.select(selectParsedStockFileSelector).pipe(
              take(1),
              map(fileState => {
                const id = fileState.file?.id;
                if (!id) {
                  return { type: 'NO_ACTION' };
                }
                return getParsedStockFile({ id });
              })
            )
          ),
          tap(() => this.toastr.success("Stock actualizado correctamente"))
        )
    );

    constructor(
        private readonly actions$: Actions,
        private readonly filesService: FilesService,
        private readonly productServices: ProductsService,
        private readonly toastr: ToastrService,
        private readonly store: Store
    ) { }

}
