import { createReducer, on } from '@ngrx/store';
import {
  clearIndividualProductsList,
  getIndividualProductsSuccess
} from "../actions/individual-products.actions";
import {initialIndividualProductsState} from "../states/individual-products.state";

export const individualProductsReducer = createReducer(
  initialIndividualProductsState,
  on(
    getIndividualProductsSuccess, (state, { products, totalProducts }) => (
      {
        ...state, 
        totalProducts,
        individualProducts: products,
      }
    )
  ),
  on(clearIndividualProductsList, (_) => ({
    ...initialIndividualProductsState
  })),
);
