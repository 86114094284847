import {Product, Tag} from "../../store/states/individual-products.state";
import {ItemDto} from "../api-dtos/responses/products.dto";

export class ProductsMapper {

  static mapProduct(product: ItemDto): Product {
    return {
      id: product.id,
      sku: product.sku,
      description: product.name,
      stock: product.stock.available,
      stockMinQuantity: product.stock.minQuantity,
      blockedStock: product.stock.blocked,
      stockPrice: product.rate.price,
      commission: product.rate.commission,
      salePrice: this.calculateSalePrice(product.rate.price, product.rate.commission),
      provider: product.provider,
      tags: product.tags.map(tag => {
        return {
          type: tag.type,
          name: tag.name
        } as Tag;
      })
    }
  }

  static calculateSalePrice(stockPrice: number, commission: number): number {
    return Number(stockPrice) * Number(commission) / 100 + Number(stockPrice);
  }

}