import { Injectable } from "@angular/core";
import { SearchParametersDto } from "../../common/api-dtos/requests/search-parameters.dto";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { HttpRequestHelper } from "../../common/helpers/http-request.helper";
import { ClientDto } from "../../common/api-dtos/responses/clients.dto";

@Injectable({ providedIn: 'root' })
export class ClientsService {

    constructor(
        private readonly http: HttpClient
    ) {}

    private readonly BASE_URL = environment.BACKEND_URL || "localhost:3000"
    private readonly CLIENTS_ENDPOINT = `${this.BASE_URL}/v1/clients`

    getClients(params: SearchParametersDto) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers, params);
        return this.http.get<ClientDto[]>(`${this.CLIENTS_ENDPOINT}`, options);
    }

}