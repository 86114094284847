import { createReducer, on } from '@ngrx/store';
import { logInFailure } from '../actions/login.actions';
import {addCompanyInfo, removeCompanyInfo} from "../actions/company.actions";
import {companyInitialState} from "../states/company.state";
import { initialFilesState } from '../states/files.state';
import { clearFiles, getFiles, getFilesFailure, getFilesSuccess, getTotalPendingDocumentsSuccess } from '../actions/files.action';

export const filesReducer = createReducer(
  initialFilesState,
  on(getFilesSuccess, (state, { files, totalFiles }) => ({
    ...state, files: files, total: totalFiles
  })),
  on(clearFiles, (state) => ({
      ...state,
      files: initialFilesState.files
  })),
  on(getTotalPendingDocumentsSuccess, (state, { totalPendingDocuments }) => ({
    ...state, totalPendingDocuments
  })),
);