import { PurchaseOrder } from "../../store/states/purchase-order.state";
import { PurchaseOrderDto } from "../api-dtos/responses/purchase-order.dto";

export class PurchaseOrderMapper {

    static fromApiToState(purchaseOrder: PurchaseOrderDto): PurchaseOrder {
        return {
            id: purchaseOrder.id,
            status: purchaseOrder.status,
            createdAt: purchaseOrder.createdAt,
            updatedAt: purchaseOrder.updatedAt,
            provider: purchaseOrder.provider ? {
                id: purchaseOrder.provider.id,
                name: purchaseOrder.provider.name,
            } : null,
            company: {
                id: purchaseOrder.company.id,
                name: purchaseOrder.company.name,
            },
            createdBy: {
                id: purchaseOrder.createdBy.id,
                name: purchaseOrder.createdBy.name,
            },
            items: purchaseOrder.items.map(item => ({
                quantity: item.quantity,
                product: {
                    id: item.product.id,
                    name: item.product.name,
                    sku: item.product.sku,
                },
                stockPrice: item.stockPrice,
            }))
        }
    }

}