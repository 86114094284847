import {createAction, props} from "@ngrx/store";
import { UserProfileDto, UserRole} from "../../common/api-dtos/responses/User.api.dto";

export const ADD_USER_INFO = '[User] Add User Info';
export const REMOVE_USER_INFO = '[User] Remove User Info';

export const addUserInfo = createAction(
  ADD_USER_INFO,
  props<{ user: UserProfileDto, id: string }>()
);
export const removeUserInfo = createAction(REMOVE_USER_INFO);
