import { ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import {initialState} from "../states";

export function localStorageSyncMiddleware(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return localStorageSync({
      keys: Object.keys(initialState),
      rehydrate: true,
      removeOnUndefined: false,
    })(reducer)(state, action);
  };
}
