import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MetaReducer, StoreModule} from '@ngrx/store';
import {EffectsModule} from "@ngrx/effects";
import {AuthenticationEffect} from "./effects/authentication.effect";
import {loggerMiddleware} from "./middlewares/logger-action.middleware";
import {environment} from "../../environments/environment";
import {ProductsEffect} from "./effects/products.effect";
import {ToastrModule} from "ngx-toastr";
import {reducers} from "./states";
import {clearStateMiddleware} from "./middlewares/clear-state.middleware";
import {localStorageSyncMiddleware} from "./middlewares/local-storage-sync.middleware";
import { FilesEffect } from './effects/files.effect';
import { ProvidersEffect } from './effects/providers.effect';
import { ParsedPriceFileEffect } from './effects/parsed-price-file.effect';
import { SaleOrdersEffect } from './effects/sale-orders.effect';
import { SaleOrderEffect } from './effects/sale-order.effect';
import { ClientsEffect } from './effects/clients.effect';
import { TasksEffect } from './effects/tasks.effect';
import { ParsedStockFileEffect } from './effects/parsed-stock-file.effect';
import { LowStockProductsEffect } from './effects/low-stock-products.effect';
import { ProviderAugmentationEffect } from './effects/provider-augmentation.effect';
import { StoreEffect } from './effects/store.effect';
import { PurchaseOrderEffect } from './effects/purchase-order.effect';

const metaReducers = [ clearStateMiddleware, localStorageSyncMiddleware ];
export const metaReducersByEnv: MetaReducer<any>[] = !environment.production ? [ ...metaReducers, loggerMiddleware ] : metaReducers;

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers: metaReducersByEnv, runtimeChecks: { strictStateImmutability: false, strictActionImmutability: false }
    }),
    EffectsModule.forRoot(
      AuthenticationEffect,
      ProductsEffect,
      FilesEffect,
      ProvidersEffect,
      ParsedPriceFileEffect,
      ParsedStockFileEffect,
      SaleOrdersEffect,
      SaleOrderEffect,
      ClientsEffect,
      LowStockProductsEffect,
      TasksEffect,
      ProviderAugmentationEffect,
      StoreEffect,
      PurchaseOrderEffect,
    ),
    ToastrModule.forRoot(),
  ]
})
export class StorageModule { }
