import { Product } from "../../store/states/individual-products.state";
import { SaleOrder, SaleOrderClient, SaleOrderItem, SaleOrderStockItem } from "../../store/states/sale-order.state";
import { TableSaleOrder } from "../../store/states/sale-orders.state";
import { SaleOrderItemRequestDto, SaleOrderRequestDto, SaleOrderStockItemRequestDto } from "../api-dtos/requests/sale-order.request.dto";
import { SaleOrderDto, SaleOrderItemDto, SaleOrderStockItemDto } from "../api-dtos/responses/sale-order.dto";
import { TableSaleOrdersDto } from "../api-dtos/responses/table-sale-orders.dto";
import { ClientsMapper } from "./clients.mapper";
import { ProductsMapper } from "./products.mapper";

export class SaleOrderMapper {

    static fromTableToState(dto: TableSaleOrdersDto) : TableSaleOrder {
        return {
            id: dto.id,
            ticketReference: dto.ticketReference,
            client: dto.client,
            status: dto.status,
            ammount: dto.ammount,
            paymentType: dto.paymentType,
            soldBy: dto.soldBy,
            createdAt: dto.createdAt,
        }
    }

    static fromDtoToSate(dto: SaleOrderDto): SaleOrder {
        return {
            id: dto.id,
            ticketReference: dto.ticketReference,
            client: dto.client ? ClientsMapper.fromClientDtoToClient(dto.client) : null,
            status: dto.status,
            paymentStatus: dto.paymentStatus,
            paymentType: dto.paymentType,
            soldBy: dto.createdBy,
            createdAt: dto.createdAt,
            items: dto.items.map(item => SaleOrderMapper.fromOrderItemDtoToState(item))
        }
    }

    static fromOrderItemDtoToState(item: SaleOrderItemDto): SaleOrderItem {
           return {
            soldPrice: item.soldPrice,
            discountAmmount: item.discountAmmount,
            discountType: item.discountType,
            finalSoldPrice: SaleOrderMapper.calculateFinalSoldPrice(
                item.discountType,
                item.discountAmmount,
                item.soldPrice,
                item.quantity
            ),
            quantity: item.quantity,
            stockItem: SaleOrderMapper.fromSaleOrderStockItemToState(item.stockItem),
        };
    }
    
    static calculateFinalSoldPrice(
        discountType: 'PERCENTAGE' | 'TOTAL',
        discountAmmount: number,
        unityFinalPrice: number,
        quantity: number
    ): number {
        const total = unityFinalPrice * quantity;
    
        if (discountType === 'PERCENTAGE') {
            const discount = total * (discountAmmount / 100);
            return total - discount;
        }
        return total - discountAmmount;
    }

    static fromSaleOrderStockItemToState(item: SaleOrderStockItemDto) : SaleOrderStockItem {
        return {
            id: item.id,
            price: item.price,
            commission: item.commission,
            description: item.description,
            sku: item.sku,
            sellPrice: ProductsMapper.calculateSalePrice(item.price, item.commission)
        }
    }

    static newIemFromStock(product: Product) : SaleOrderItem {
        return {
            soldPrice: product.salePrice,
            quantity: 1,
            finalSoldPrice: SaleOrderMapper.calculateFinalSoldPrice('TOTAL', 0, product.salePrice, 1),
            discountType: 'TOTAL',
            discountAmmount: 0,
            stockItem: SaleOrderMapper.fromProductToOrderItemStock(product)
        }
    }

    static fromProductToOrderItemStock(product: Product) : SaleOrderStockItem {
        return {
            id: product.id,
            price: product.stockPrice,
            commission: product.commission,
            sellPrice: product.salePrice,
            description: product.description,
            sku: product.sku
        }
    }

    static fromStateToDto(state: SaleOrder): SaleOrderRequestDto {
        return {
            id: state.id,
            ticketReference: state.ticketReference,
            client: state.client ? ClientsMapper.fromClientToDto(state.client) : undefined,
            status: state.status,
            paymentStatus: state.paymentStatus,
            paymentType: state.paymentType,
            createdBy: state.soldBy,
            createdAt: state.createdAt,
            items: state.items.map(item => SaleOrderMapper.fromStateItemToDto(item))
        };
    }

    static fromStateItemToDto(item: SaleOrderItem): SaleOrderItemRequestDto {
        return {
            soldPrice: item.soldPrice,
            quantity: item.quantity,
            discountType: item.discountType,
            discountAmmount: item.discountAmmount,
            stockItem: SaleOrderMapper.fromStateStockItemToDto(item.stockItem)
        };
    }

    static fromStateStockItemToDto(item: SaleOrderStockItem): SaleOrderStockItemRequestDto {
        return {
            id: item.id
        };
    }
}