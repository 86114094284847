import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from "rxjs";
import { getTaskDownloadSignedUrl, getTasks, getTasksFailure, getTasksSuccess, resumeParsePriceFile, resumeParseStockFile } from "../actions/tasks.action";
import { StoreService } from "../../main-app/services/store.service";
import { createStoreProduct, deleteStoreProduct, deleteStoreProductFailure, deleteStoreProductSuccess, getStoreProducts, getStoreProductsFailure, getStoreProductsSuccess } from "../actions/store.action";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { get } from "http";
import { SearchParametersDto } from "../../common/api-dtos/requests/search-parameters.dto";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class StoreEffect {

    getStoreProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getStoreProducts),
            exhaustMap(action => {
                return this.storeService.getStoreProducts(action.params).pipe(
                    map(response => {
                        return getStoreProductsSuccess({ products: response.products, totalProducts: response.totalProducts })
                    }),
                    catchError(error => {
                        return of(getStoreProductsFailure())
                    })
                )
            }
            )
        )
    );

    getStoreProductsFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getStoreProductsFailure),
            tap(() => {
                this.router.navigate(['app/store']);
            })
        ), { dispatch : false }
    );

    createStoreProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createStoreProduct),
            exhaustMap(action => {
                return this.storeService.createStoreProduct(action.product).pipe(
                    map(() => {
                        this.router.navigate(['app/store']);
                    }),
                    catchError(error => {
                        return of(getStoreProductsFailure())
                    })
                )
            })
        ), { dispatch: false }
    );

    deleteStoreProductFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteStoreProductFailure),
            tap(() => {
                this.toastr.warning('Hubo un error al eliminar el producto de la tienda, por favor intenta de nuevo');
            })
        ), { dispatch: false }
    );

    deleteStoreProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteStoreProduct),
            exhaustMap(action => {
                return this.storeService.deleteStoreProduct(action.id).pipe(
                    map(() => {
                        this.store.dispatch(getStoreProducts({
                            params: new SearchParametersDto({
                                page: 1,
                                limit: 10,
                                searchTerm: ''
                            })
                        }));
                        return this.router.navigateByUrl('app/store');
                    }),
                    catchError(_ => {
                        return of(deleteStoreProductFailure())
                    })
                )
            }
            )
        ), { dispatch: false });

    constructor(
        private readonly actions$: Actions,
        private readonly storeService: StoreService,
        private readonly router: Router,
        private readonly store: Store,
        private readonly toastr: ToastrService
    ) { }

}
