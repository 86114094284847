import { createReducer, on } from '@ngrx/store';
import { logInFailure } from '../actions/login.actions';
import {addUserInfo, removeUserInfo} from "../actions/users.actions";
import {initialUserState} from "../states/user.state";

export const usersReducer = createReducer(
  initialUserState,
  on(addUserInfo, (state, { user }) => ({
    ...state,
    user: user
  })),
  on(logInFailure, (state) => ({
    ...state,
    user: null
  })),
  on(removeUserInfo, (state) => ({
    ...state,
    user: initialUserState.user
  })),
);
