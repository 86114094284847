import { createReducer, on } from '@ngrx/store';
import { initTableSaleOrderState } from '../states/sale-orders.state';
import { clearSaleOrders, getSaleOrders, getSaleOrdersSuccess } from '../actions/sale-orders.action';

export const saleOrdersReducer = createReducer(
  initTableSaleOrderState,
  on(getSaleOrdersSuccess, (state, { orders, totalOrders }) => ({
    ...state,
    totalSaleOrders: totalOrders,
    saleOrders: orders, 
  })),
  on(clearSaleOrders, (state) => ({
    ...state,
    saleOrders: []
  })),
);
