import {createAction, props} from "@ngrx/store";
import { UserCompanyDto} from "../../common/api-dtos/responses/User.api.dto";

export const ADD_COMPANY_INFO = '[Company] Add Company Info';
export const REMOVE_COMPANY_INFO = '[Company] Remove Company Info';

export const addCompanyInfo = createAction(
  ADD_COMPANY_INFO,
  props<{ company: UserCompanyDto }>()
);
export const removeCompanyInfo = createAction(REMOVE_COMPANY_INFO);
