import { createReducer, on } from '@ngrx/store';
import { initialProvidersState } from '../states/providers.state';
import { cleanProvidersList, getProvidersList, getProvidersListSuccess } from '../actions/providers.action';

export const providersReducer = createReducer(
  initialProvidersState,
  on(getProvidersListSuccess, (state, { providers }) => ({
    ...state, providers
  })),
  on(cleanProvidersList, (state) => ({
    ...state,
    providers: []
  })),
);
