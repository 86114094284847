import {Observable, of} from "rxjs";
import {CredentialsActionDto} from "../../common/api-dtos/requests/credentials.dto";
import {UserApiDto} from "../../common/api-dtos/responses/User.api.dto";
import {HttpClient} from "@angular/common/http";
import {HttpRequestHelper} from "../../common/helpers/http-request.helper";
import {Injectable} from "@angular/core";
import { environment } from '../../../environments/environment';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private readonly BASE_URL = environment.BACKEND_URL || "localhost:3000"
    private readonly AUTHENTICATION_ENDPOINT = `${this.BASE_URL}/v1/authentication`

    constructor(
      private readonly http: HttpClient
    ) {}

  logIn(credentials: CredentialsActionDto): Observable<UserApiDto> {
    const headers = HttpRequestHelper.getHttpHeaders(credentials);
    const options = HttpRequestHelper.getHttpOptions(headers);
    return this.http.post<UserApiDto>(`${this.AUTHENTICATION_ENDPOINT}/login`, credentials, options);
  }

}
