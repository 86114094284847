import { Action, ActionReducer } from '@ngrx/store';
import {environment} from "../../../environments/environment";
import {LOG_OUT_SUCCESS_ACTION} from "../actions/logout.actions";
import {initialState} from "../states";

export function clearStateMiddleware(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action: Action) => {
    if (action.type === LOG_OUT_SUCCESS_ACTION) {
      state = initialState
    }
    return reducer(state, action);
  };
}
