import { createReducer, on } from '@ngrx/store';
import { initialPurchaseOrderState } from '../states/purchase-order.state';
import { cleanPurchaseOrdersList, clearIndividualPurchaseOrder, completePurchaseOrder, completePurchaseOrderFailure, completePurchaseOrderSuccess, downloadPurchaseOrderPdf, getPurchaseOrdersList, getPurchaseOrdersListFailure, getPurchaseOrdersListSuccess, getSinglePurchaseOrder, getSinglePurchaseOrderFailure, getSinglePurchaseOrderSuccess, processPurchaseOrder, processPurchaseOrderFailure, processPurchaseOrderSuccess } from '../actions/purchase-order.action';

export const purchaseOrderReducer = createReducer(
    initialPurchaseOrderState,
    on(getPurchaseOrdersList, (state) => ({
        ...state,
        loading: true,
        error: null
    })),
    on(getPurchaseOrdersListSuccess, (state, { orders, totalOrders }) => ({
        ...state,
        orders,
        totalOrders,
        loading: false,
        error: null
    })),
    on(getPurchaseOrdersListFailure, (state) => ({
        ...state,
        loading: false,
        error: 'Error loading purchase orders'
    })),
    on(getSinglePurchaseOrder, (state) => ({
        ...state,
        loading: true,
        error: null
    })),
    on(getSinglePurchaseOrderSuccess, (state, { order }) => ({
        ...state,
        individualOrder: order,
        loading: false,
        error: null
    })),
    on(getSinglePurchaseOrderFailure, (state) => ({
        ...state,
        loading: false,
        error: 'Error loading purchase order'
    })),
    on(processPurchaseOrder, (state) => ({
        ...state,
        loading: true,
        error: null
    })),
    on(processPurchaseOrderSuccess, (state, { order }) => ({
        ...state,
        loading: false,
        individualOrder: order,
        error: null
    })),
    on(processPurchaseOrderFailure, (state) => ({
        ...state,
        loading: false,
        error: 'Error processing purchase order'
    })),
    on(cleanPurchaseOrdersList, (state) => ({
        ...state,
        orders: [],
        individualOrder: null,
        loading: false,
        error: null
    })),
    on(completePurchaseOrder, (state) => ({
        ...state,
        loading: true,
        error: null
    })),
    on(completePurchaseOrderSuccess, (state, { order }) => ({
        ...state,
        individualOrder: order,
        loading: false,
        error: null
    })),
    on(completePurchaseOrderFailure, (state) => ({
        ...state,
        loading: false,
        error: 'Error completing purchase order'
    })),
    on(clearIndividualPurchaseOrder, (state) => ({
        ...state,
        individualOrder: null,
    })),
    on(downloadPurchaseOrderPdf, (state) => ({
        ...state,
        loading: true,
        error: null
    })),
    on(downloadPurchaseOrderPdf, (state) => ({
        ...state,
        loading: false,
        error: null
    })),
    on(downloadPurchaseOrderPdf, (state) => ({
        ...state,
        loading: false,
        error: null,
    }))
);
