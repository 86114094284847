import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { PurchaseOrderDto } from "../../common/api-dtos/responses/purchase-order.dto";
import { HttpRequestHelper } from "../../common/helpers/http-request.helper";
import { UpdatePurchaseOrderDto } from "../../common/api-dtos/requests/update-purchase-order.dto";

@Injectable({ providedIn: 'root' })
export class PurchaseOrderService {

    constructor(
        private readonly http: HttpClient
    ) {}

    private readonly BASE_URL = environment.BACKEND_URL || "localhost:3000"
    private readonly PURCHASE_ORDERS_ENDPOINT = `${this.BASE_URL}/api/v1/purchase-orders`

    getPurchaseOrders(limit: number, offset: number) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<{ totalOrders: number, orders: PurchaseOrderDto[] }>(`${this.PURCHASE_ORDERS_ENDPOINT}?limit=${limit}&offset=${offset}`, options);
    }

    createDraftPurchaseOrder(fallbackOrder?: UpdatePurchaseOrderDto) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.post<PurchaseOrderDto>(this.PURCHASE_ORDERS_ENDPOINT, fallbackOrder, options);
    }

    processPurchaseOrder(id: string) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.post<PurchaseOrderDto>(`${this.PURCHASE_ORDERS_ENDPOINT}/${id}/process`, {}, options);
    }

    completePurchaseOrder(id: string) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.post<PurchaseOrderDto>(`${this.PURCHASE_ORDERS_ENDPOINT}/${id}/complete`, {}, options);
    }

    updatePurchaseOrder(id: string, order: UpdatePurchaseOrderDto) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.patch<PurchaseOrderDto>(`${this.PURCHASE_ORDERS_ENDPOINT}/${id}`, order, options);
    }

    getSinglePurchaseOrder(id: string) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<PurchaseOrderDto>(`${this.PURCHASE_ORDERS_ENDPOINT}/${id}`, options);
    }

    getDownloadPdf(id: string) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<{ url: string, fileName: string }>(`${this.PURCHASE_ORDERS_ENDPOINT}/${id}/download-pdf`, options);
    }
    
}