import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpRequestHelper } from "../../common/helpers/http-request.helper";
import { ProviderAugmentation } from '../../common/api-dtos/responses/provider-augmentation.interface';
import { HistoricalPriceMapDto } from "../../common/api-dtos/responses/product-historic-data.dto";
import { ChartData } from "../../common/api-dtos/responses/product-augmentation.dto";

@Injectable({ providedIn: 'root' })
export class DashboardService {

    constructor(
        private readonly http: HttpClient
    ) {}

    private readonly BASE_URL = environment.BACKEND_URL || "localhost:3000"
    private readonly DASHBOARD_ENDPOINT = `${this.BASE_URL}/v1/dashboard`

    getProviders() {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<ProviderAugmentation[][]>(`${this.DASHBOARD_ENDPOINT}/providers-monthly-augmentation`, options);
    }

    getProductHistoricalPrices(sku: string) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<HistoricalPriceMapDto>(`${this.DASHBOARD_ENDPOINT}/product-historical-prices?sku=${sku}`, options);
    }

    getProductsAugmentation(skus: string[]) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<ChartData[]>(`${this.DASHBOARD_ENDPOINT}/products-monthly-augmentation?sku=${skus.join(',')}`, options);
    }

}