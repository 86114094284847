import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, exhaustMap, map, switchMap, tap} from 'rxjs/operators';
import {of} from "rxjs";
import { getProvidersList, getProvidersListFailure, getProvidersListSuccess } from "../actions/providers.action";
import { ProviderService } from "../../main-app/services/providers.service";
import { ProviderMapper } from "../../common/mappers/provider.mapper";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class ProvidersEffect {

  getProviders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProvidersList),
      exhaustMap(action => {
          return this.providersService.getProviders(action.request).pipe(
            map(response => {
               return getProvidersListSuccess({
                 providers: response.map(provider => ProviderMapper.fromApiToState(provider))
               })
            }),
            catchError(error => of(getProvidersListFailure()))
          )
        }
      )
    )
  );

  getProvidersFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProvidersListFailure),
      tap(() => {
        this.toastService.warning("Hubo un error al cargar los proveedores, por favor intenta de nuevo");
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private providersService: ProviderService,
    private readonly toastService: ToastrService
  ) {}

}
