import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {of} from "rxjs";
import { getHistoricalProductPrices, getHistoricalProductPricesFailure, getHistoricalProductPricesSuccess, getProductsAugmentation, getProductsAugmentationFailure, getProductsAugmentationSuccess, getProvidersAugmentation, getProvidersAugmentationFailure, getProvidersAugmentationSuccess } from "../actions/dashboards.action";
import { DashboardService } from "../../main-app/services/dashboard.service";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class ProviderAugmentationEffect {

  getProviderAugmentation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProvidersAugmentation),
      exhaustMap(_ => {
          return this.dashboardService.getProviders().pipe(
            map(response => {
               return getProvidersAugmentationSuccess({
                    providersAugmentation: response
               })
            }),
            catchError(error => of(getProvidersAugmentationFailure()))
          )
        }
      )
    )
  );

  getProviderAugmentationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProvidersAugmentationFailure),
      map(() => {
        this.toastr.warning("Hubo un error al cargar la información de los proveedores, por favor intenta de nuevo");
      })
    ), { dispatch: false }
  );

  getHistoricalProductPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHistoricalProductPrices),
      exhaustMap(action => {
          return this.dashboardService.getProductHistoricalPrices(action.sku).pipe(
            map(response => {
               return getHistoricalProductPricesSuccess({
                    historicalProductPrices: response
               })
            }),
            catchError(error => of(getHistoricalProductPricesFailure()))
          )
        }
      )
    )
  );

  getHistoricalProductPricesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHistoricalProductPricesFailure),
      map(() => {
        this.toastr.warning("Hubo un error al cargar la información de los precios históricos, por favor intenta de nuevo");
      })
    ), { dispatch: false }
  );

  getProductsAugmentation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProductsAugmentation),
      exhaustMap(action => {
          return this.dashboardService.getProductsAugmentation(action.sku).pipe(
            map(response => {
               return getProductsAugmentationSuccess({
                    productsAugmentation: response
               })
            }),
            catchError(error => of(getProductsAugmentationFailure()))
          )
        }
      )
    )
  );

  getProductsAugmentationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProductsAugmentationFailure),
      map(() => {
        this.toastr.warning("Hubo un error al cargar la información de los productos, por favor intenta de nuevo");
      })
    ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private readonly dashboardService: DashboardService,
    private readonly toastr: ToastrService,
  ) {}

}
