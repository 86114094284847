import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoggedInLayout} from "./main-app/layouts/logged-in-layout/logged-in-layour.component";
import {canActivateLoggedOutModule} from "./common/guards/auth-module.guard";
import {canActivateLoggedInModule} from "./common/guards/page.guard";

const routes: Routes = [
  {
    path: 'authentication',
    loadChildren: () => import('./authentication/authentication.module')
      .then(m => m.AuthenticationModule),
    canActivateChild: [ canActivateLoggedOutModule ]
  },
  {
    path: "app",
    component: LoggedInLayout,
    loadChildren: () =>
      import("./main-app/main-app.module").then((m) => m.MainAppModule),
    canActivateChild: [ canActivateLoggedInModule ]
  },
  {
    path: "admin",
    component: LoggedInLayout,
    loadChildren: () => import('./administration/admin.module').then(m => m.AuthenticationModule),
    canActivateChild: [ canActivateLoggedInModule ]
  },
  {
    path: '**',
    loadChildren: () => import('./pre-authentication/pre-authentication.module')
      .then(m => m.PreAuthenticationModule),
    canActivateChild: [ canActivateLoggedOutModule ]
  },
  {
    path: '*',
    loadChildren: () => import('./pre-authentication/pre-authentication.module')
      .then(m => m.PreAuthenticationModule),
    canActivateChild: [ canActivateLoggedOutModule ]
  },
  // other routes
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
