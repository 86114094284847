import { createReducer, on } from '@ngrx/store';
import { logInFailure } from '../actions/login.actions';
import {addCompanyInfo, removeCompanyInfo} from "../actions/company.actions";
import {companyInitialState} from "../states/company.state";

export const companyReducer = createReducer(
  companyInitialState,
  on(addCompanyInfo, (state, { company }) => ({
    ...state,
    company: company
  })),
  on(logInFailure, (state) => ({
    ...state,
    company: null
  })),
  on(removeCompanyInfo, (state) => ({
      ...state,
      company: companyInitialState.company
  })),
);