import { Client } from "../../store/states/clients.state";
import { ClientRequestDto } from "../api-dtos/requests/client.request.dto";
import { ClientDto } from "../api-dtos/responses/clients.dto";

export class ClientsMapper {

    static fromClientDtoToClient(dto: ClientDto) : Client {
        return {
            id: dto.id,
            legalName: dto.legalName,
            address: dto.address,
            city: dto.city,
            postalCode: dto.postalCode,
            email: dto.email,
            phoneNumber: dto.phoneNumber,
            documentationType: dto.documentationType,
            documentationNumber: dto.documentationNumber
        }
    }

    static fromClientToDto(client: Client): ClientRequestDto {
        return {
            legalName: client.legalName,
            address: client.address,
            city: client.city,
            postalCode: client.postalCode,
            email: client.email,
            phoneNumber: client.phoneNumber,
            documentationType: client.documentationType,
            documentationNumber: client.documentationNumber
        }
    }

}