import {Component, OnDestroy, OnInit} from "@angular/core";
import {Router } from "@angular/router";
import {LocalStorageService} from "./common/services/local-storage.services";
import {Store} from "@ngrx/store";

declare var LiveAgent: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    let scriptUrl = "https://mystockseguro.ladesk.com/scripts/track.js";
    let node = document.createElement("script");
    node.src = scriptUrl;
    node.id = "la_x2s6df8d";
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    node.onload = function (e) {
      LiveAgent.createButton("c935q86x", document.getElementById("chatButton"));
    };
    document.getElementsByTagName("head")[0].appendChild(node);
  }

}
