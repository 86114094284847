import { createReducer, on } from '@ngrx/store';
import { logInFailure } from '../actions/login.actions';
import {addUserInfo, removeUserInfo} from "../actions/users.actions";
import {initialUserState} from "../states/user.state";
import { initClientsState } from '../states/clients.state';
import { clearClients, getClientsSuccess } from '../actions/clients.action';

export const clientsReducer = createReducer(
  initClientsState,
  on(getClientsSuccess, (state, { clients }) => ({
    ...state, clients
  })),
  on(clearClients, (state) => ({
    ...state,
    clients: []
  })),
);
