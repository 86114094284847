export interface Company {
  id: string;
  name: string;
}

export interface CompanyState {
  company: Company | null;
  loading: boolean,
  error: string | null,
}

export const companyInitialState: CompanyState = {
  company: null,
  loading: false,
  error: null
}