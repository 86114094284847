import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, exhaustMap, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from "rxjs";
import { SaleOrderService } from "../../main-app/services/sale-orders.service";
import { SaleOrderMapper } from "../../common/mappers/sale-order.mapper";
import { createSaleOrder, createSaleOrderFailure, createSaleOrderSuccess, downloadEstimate, getSaleOrder, getSaleOrderFailure, getSaleOrderSuccess, localOrderItemUpdate, processSaleOrder, processSaleOrderFailure, processSaleOrderSuccess, updateSaleOrder, updateSaleOrderFailure, updateSaleOrderProductPrice, updateSaleOrderSuccess } from "../actions/sale-order.action";
import { select, Store } from "@ngrx/store";
import { selectSaleOrderSelector } from "../states/sale-order.state";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class SaleOrderEffect {

  saleOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSaleOrder),
      exhaustMap(action => {
          return this.saleOrdersService.getSaleOrderById(action.id).pipe(
            map(response => {
               return getSaleOrderSuccess({
                 order: SaleOrderMapper.fromDtoToSate(response),
               })
            }),
            catchError(error => {
              return of(getSaleOrderFailure())
            })
          )
        }
      )
    )
  );

  saleOrderFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSaleOrderFailure),
      tap(() => {
        this.toastr.warning("Hubo un error al traer la orden de venta, por favor intenta de nuevo");
        this.router.navigate(['app/sale-orders']);
      })
    ),
    { dispatch: false }
  );

  processSaleOrder$ = createEffect(() => this.actions$.pipe(
    ofType(processSaleOrder),
    withLatestFrom(this.store.pipe(select(selectSaleOrderSelector))),
    exhaustMap(([action, orderState]) => {
      const order = orderState?.saleOrder;

      if (!order) {
        // Handle the case where the order is not available in the state
        return of(processSaleOrderFailure());
      }
      const orderDto = SaleOrderMapper.fromStateToDto(order);
      return this.saleOrdersService.processSaleOrder(orderDto).pipe(
        map(response => processSaleOrderSuccess({ order: SaleOrderMapper.fromDtoToSate(response) })),
        catchError(error => of(processSaleOrderFailure()))
      );
    })
  ));

  processSaleOrderSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(processSaleOrderSuccess),
    switchMap(action => {
      return this.store.pipe(
        select(selectSaleOrderSelector),
        exhaustMap(orderState => {
          const orderId = orderState?.saleOrder?.id;
          if (orderId) {
            return of(getSaleOrder({ id: orderId }));
          }
          return of(); // In case the order ID is not found, you can handle it gracefully here
        })
      );
    })
  ));

  processSaleOrderFailure$ = createEffect(() => this.actions$.pipe(
    ofType(processSaleOrderFailure),
    tap(() => {
      this.toastr.error('Error procesando la orden de venta');
      this.router.navigate(['app/sale-orders']);
    })
  ), { dispatch: false });

  updateSaleOrder$ = createEffect(() => this.actions$.pipe(
    ofType(updateSaleOrder),
    withLatestFrom(this.store.pipe(select(selectSaleOrderSelector))),
    exhaustMap(([action, orderState]) => {
      const order = orderState?.saleOrder;
      if (!order) {
        return of(updateSaleOrderFailure());
      }
      const orderDto = SaleOrderMapper.fromStateToDto(order);
      return this.saleOrdersService.updateSaleOrder(orderDto).pipe(
        map(response => updateSaleOrderSuccess({ order: SaleOrderMapper.fromDtoToSate(response) })),
        catchError(error => of(updateSaleOrderFailure()))
      );
    })
  ));

  
  updateSaleOrderSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(updateSaleOrderSuccess),
    switchMap(action => {
      return this.store.pipe(
        select(selectSaleOrderSelector),
        exhaustMap(orderState => {
          const orderId = orderState?.saleOrder?.id;
          if (orderId) {
            return of(getSaleOrder({ id: orderId }));
          }
          return of(); // In case the order ID is not found, you can handle it gracefully here
        })
      );
    })
  ));

  updateSaleOrderFailure$ = createEffect(() => this.actions$.pipe(
    ofType(updateSaleOrderFailure),
    tap(() => {
      this.toastr.error('Hubo un error actualizando la orden de venta');
      this.router.navigate(['app/sale-orders']);
    })
  ), { dispatch: false });

  createSaleOrder$ = createEffect(() => this.actions$.pipe(
    ofType(createSaleOrder),
    exhaustMap(action => {
      return this.saleOrdersService.createSaleOrder().pipe(
        map(response => {
          return createSaleOrderSuccess({ order: response });
        }),
        catchError(error => of(createSaleOrderFailure()))
      );
    })
  ));

  createSaleOrderSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(createSaleOrderSuccess),
    switchMap(action => {
      return this.router.navigate([`app/sale-orders/${action.order.id}`]);
    })
  ), { dispatch: false });

  createSaleOrderFailure$ = createEffect(() => this.actions$.pipe(
    ofType(createSaleOrderFailure),
    tap(() => {
      this.toastr.warning('Hubo un error creando la orden de venta, por favor intenta de nuevo');
      this.router.navigate(['app/sale-orders']);
    })
  ), { dispatch: false });

  downloadEstimate$ = createEffect(() => this.actions$.pipe(
    ofType(downloadEstimate),
    exhaustMap(action => {
      return this.saleOrdersService.getEstimateDownloadUrl(action.id).pipe(
        tap(async ({ url, fileName }) => {
            const link = document.createElement('a');
            await new Promise((resolve) => {
                setTimeout(() => {
                    link.href = url;
                    link.name = fileName;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    resolve(true)
                }, 3000)
            })
        })
      );
    })
  ), { dispatch: false });

  downloadEstimateFailure$ = createEffect(() => this.actions$.pipe(
    ofType(downloadEstimate),
    tap(() => {
      this.toastr.warning('Error descargando el presupuesto, por favor intenta de nuevo');
    })
  ), { dispatch: false });

  updateItemPrice$ = createEffect(() => this.actions$.pipe(
    ofType(updateSaleOrderProductPrice),
    exhaustMap(action => {
      return this.saleOrdersService.updateItemPrice(action.item, action.id).pipe(
        map(response => {
          return getSaleOrder({ id: action.id });
        }),
        catchError(error => of(updateSaleOrderFailure()))
      );
    })
  ));

  updateItemPriceFailure$ = createEffect(() => this.actions$.pipe(
    ofType(updateSaleOrderFailure),
    tap(() => {
      this.toastr.warning('Hubo un error actualizando el precio del producto');
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private saleOrdersService: SaleOrderService,
    private store: Store,
    private readonly router: Router,
    private readonly toastr: ToastrService
  ) {}

}
