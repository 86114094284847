import { createReducer, on } from '@ngrx/store';
import { initTableSaleOrderState } from '../states/sale-orders.state';
import { clearSaleOrders, getSaleOrders, getSaleOrdersSuccess } from '../actions/sale-orders.action';
import { initSaleOrderState, SaleOrder, SaleOrderItem } from '../states/sale-order.state';
import { clearSaleOrder, getSaleOrderSuccess, localAddClientToOrder, localAddItemToOrder, localOrderItemUpdate, localRemoveItemFromOrder, updateSaleOrderSuccess } from '../actions/sale-order.action';
import { SaleOrderMapper } from '../../common/mappers/sale-order.mapper';

export const saleOrderReducer = createReducer(
  initSaleOrderState,
  on(getSaleOrderSuccess, (state, { order }) => ({
    ...state,
    saleOrder: order,
  })),
  on(clearSaleOrder, (state) => ({
    ...state,
    saleOrder: null
  })),
  on(localOrderItemUpdate, (state, { item }) => {
    const updatedItems = state.saleOrder?.items.map((existingItem) => {
      if (existingItem.stockItem.sku !== item.stockItem.sku) return existingItem;
      
      const finalSoldPrice = SaleOrderMapper.calculateFinalSoldPrice(
        item.discountType,
        item.discountAmmount,
        item.soldPrice,
        item.quantity
    )
      
      return {
        ...existingItem,
        ...{
          ...item,
          finalSoldPrice: finalSoldPrice
        }
      }
    }) || [];
    return {
      ...state,
      saleOrder: { ...state.saleOrder, items: updatedItems } as SaleOrder,
      pendingChanges: true
    };
  }),
  on(localRemoveItemFromOrder, (state, { item }) => {
    const updatedItems = state.saleOrder?.items.filter((existingItem) => existingItem.stockItem.sku !== item.stockItem.sku) || [];
    return {
      ...state,
      saleOrder: { ...state.saleOrder, items: updatedItems } as SaleOrder,
      pendingChanges: true
    };
  }),
  on(localAddItemToOrder, (state, { item }) => {
    const saleOrderItem = SaleOrderMapper.newIemFromStock(item);
    const updatedItems =  [ ...(state.saleOrder?.items || []), saleOrderItem]
    const saleOrder = { ...state.saleOrder, items: updatedItems } as SaleOrder
    return { ...state, saleOrder, pendingChanges: true };
  }),
  on(localAddClientToOrder, (state, { client }) => {
    const saleOrder = { ...state.saleOrder, client } as SaleOrder
    return { ...state, saleOrder, pendingChanges: true };
  }),
);
