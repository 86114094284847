import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, exhaustMap, map, switchMap, tap} from 'rxjs/operators';
import {of} from "rxjs";
import { getProvidersList, getProvidersListFailure, getProvidersListSuccess } from "../actions/providers.action";
import { ProviderService } from "../../main-app/services/providers.service";
import { ProviderMapper } from "../../common/mappers/provider.mapper";
import { getSaleOrders, getSaleOrdersFailure, getSaleOrdersSuccess } from "../actions/sale-orders.action";
import { SaleOrderService } from "../../main-app/services/sale-orders.service";
import { SaleOrderMapper } from "../../common/mappers/sale-order.mapper";
import { getSaleOrder, getSaleOrderFailure, getSaleOrderSuccess, localOrderItemUpdate } from "../actions/sale-order.action";
import { getClients, getClientsFailure, getClientsSuccess } from "../actions/clients.action";
import { ClientsService } from "../../main-app/services/clients.service";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class ClientsEffect {

  getClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClients),
      exhaustMap(action => {
          return this.clientsService.getClients(action.searchTerm).pipe(
            map(response => {
               return getClientsSuccess({
                 clients: response
               })
            }),
            catchError(error => {
              return of(getClientsFailure())
            })
          )
        }
      )
    )
  );

  getClientsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClientsFailure),
      tap(() => {
        this.toastService.warning("Hubo un error al cargar los clientes, por favor intenta de nuevo");
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private clientsService: ClientsService,
    private toastService: ToastrService
  ) {}

}
