import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, exhaustMap, map, switchMap, tap} from 'rxjs/operators';
import {of} from "rxjs";
import {ProductsService} from "../../main-app/services/products.service";
import {
  getGroupedProducts,
  getGroupedProductsFailure,
  getGroupedProductsSuccess
} from "../actions/grouped-products.actions";
import {GroupedProductMapper} from "../../common/mappers/grouped-product.mapper";
import {
  createIndividualProduct,
  createIndividualProductFailure,
  createIndividualProductSuccess,
  deleteIndividualProduct,
  deleteIndividualProductFailure,
  deleteIndividualProductSuccess,
  getIndividualProducts,
  getIndividualProductsFailure,
  getIndividualProductsSuccess,
  updateIndividualProduct,
  updateIndividualProductFailure,
  updateIndividualProductSuccess
} from "../actions/individual-products.actions";
import {ProductsMapper} from "../../common/mappers/products.mapper";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { getLowStockProducts } from "../actions/low-stock-products.action";
import { SearchParametersDto } from "../../common/api-dtos/requests/search-parameters.dto";
import { updateItemStockByParsedFileId, updateItemStockByParsedFileIdFailure, updateItemStockByParsedFileIdSuccess } from "../actions/parsed-stock-file.action";

@Injectable({ providedIn: 'root' })
export class ProductsEffect {

  productGrouped$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGroupedProducts),
      exhaustMap(action => {
          return this.productServices.getGroupedItems(action.searchParameters).pipe(
            map(response => {
               return getGroupedProductsSuccess({
                 products: response.products.map(groupedProduct => GroupedProductMapper.fromApiToState(groupedProduct)),
                 totalProducts: response.totalProducts
               })
            }),
            catchError(error => of(getGroupedProductsFailure()))
          )
        }
      )
    )
  );

  productGroupedFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGroupedProductsFailure),
      tap(() => this.toastrService.warning("Hubo un error al traer los productos agrupados, por favor intente de nuevo")),
    ), { dispatch: false }
  );

  individualProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIndividualProducts),
      exhaustMap(action => {
          return this.productServices.getItems(action.searchParameters).pipe(
            map(response => {
               return getIndividualProductsSuccess({
                 products: response.products.map(product => ProductsMapper.mapProduct(product)),
                 totalProducts: response.total
               })
            }),
            catchError(error => of(getIndividualProductsFailure()))
          )
        }
      )
    )
  );

  individualProductsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIndividualProductsFailure),
      tap(() => this.toastrService.warning("Hubo un error al traer los productos individuales, por favor intente de nuevo")),
    ), { dispatch: false }
  );

  createIndividualProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createIndividualProduct),
      exhaustMap(action => {
          return this.productServices.createItem(action.product).pipe(
            map(() => {
               return createIndividualProductSuccess({ successRedirect: action.successRedirect })
            }),
            catchError(error => of(createIndividualProductFailure()))
          )
        }
      )
    )
  );

  createIndividualProductSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createIndividualProductSuccess),
      tap(action => {
        this.toastrService.success("Producto creado con éxito");
        if (action.successRedirect) {
          return this.router.navigateByUrl(action.successRedirect);
        }
        return this.router.navigate(["app/inventory"]);
      })
    ), { dispatch: false }
  );

  updateIndividualProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateIndividualProduct),
      switchMap(action => {
          return this.productServices.updateProduct(action.id, action.product).pipe(
            map((response) => updateIndividualProductSuccess({ 
              product: ProductsMapper.mapProduct(response)
            })),
            catchError(() => of(updateIndividualProductFailure()))
          )
        }
      )
    )
  );

  updateIndividualProductSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateIndividualProductSuccess),
      tap(() => {
        this.store.dispatch(getIndividualProducts({
          searchParameters: { limit: 10, offset: 0 }}));
        this.store.dispatch(getLowStockProducts({
          searchParameters: new SearchParametersDto({
            page: 1, limit: 10, searchTerm: ''
          })
        }));
        this.toastrService.success("Producto actualizado con éxito");
      })
    ), { dispatch: false }
  );

  updateIndividualProductFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateIndividualProductFailure),
      tap(() => {
        this.toastrService.warning("Hubo un error al actualizar el producto, por favor intente de nuevo");
      })
    ), { dispatch: false }
  );

  deleteIndividualProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteIndividualProduct),
      switchMap(({ id }) => {
          return this.productServices.deleteItem(id).pipe(
            map(() => {
              return deleteIndividualProductSuccess()
            }),
            catchError((e: any) => {
              return of(deleteIndividualProductFailure())
            })
          )
        }
      ))
    );

    deleteIndividualProductSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(deleteIndividualProduct),
        tap(() => {
          this.store.dispatch(getIndividualProducts({ searchParameters: { limit: 10, offset: 0 } }));
          this.store.dispatch(getLowStockProducts({
            searchParameters: new SearchParametersDto({
              page: 1, limit: 10, searchTerm: ''
            })
          }));
        })
      ), { dispatch: false }
    );

    deleteIndividualProductFailure$ = createEffect(() =>
      this.actions$.pipe(
        ofType(deleteIndividualProduct),
        tap(() => {
          this.toastrService.warning("Hubo un error al eliminar el producto, por favor intente de nuevo");
        })
      ), { dispatch: false }
    );

  constructor(
    private actions$: Actions,
    private productServices: ProductsService,
    private toastrService: ToastrService,
    private readonly router: Router,
    private readonly store: Store
  ) {}

}
