import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {HttpRequestHelper} from "../helpers/http-request.helper";

@Injectable({ providedIn: 'root' })
export class PermissionService {

  constructor(
    private readonly router: Router
  ) {}

  async canActivateLoggedOutModule() {
    const isLogged = this.isLoggedIn();
    if (isLogged) {
      await this.router.navigateByUrl("/app/dashboard");
      return false;
    }

    return true;
  }

  async canActivateLoggedInModule() {
    const isLogged = this.isLoggedIn();
    if (!isLogged) {
      await this.router.navigateByUrl("/authentication/login");
      return false;
    }

    return true;
  }

  isLoggedIn() {
    const token = HttpRequestHelper.getLocalStorageToken();
    return !(!token || token === "undefined");
  }

}