import { FileTableDto } from "../api-dtos/responses/files.dto";
import { File } from "../../store/states/files.state";
import { ParsedPriceData, ParsePriceFile } from "../../store/states/parsed-price-file.state";
import { ParsedPriceDataDto, ParsedPriceFileDto } from "../api-dtos/responses/parsed-price-file.dto";
import { ParsedStockDataDto, ParsedStockFileDto } from "../api-dtos/responses/parsed-stock-file.dto";
import { ParsedstockData, ParsedStockFile } from "../../store/states/parsed-stock-file.state";

export class FileMapper {
    
    static fromTableDtoToModel(file: FileTableDto) : File {
        return {
            id: file.id,
            type: file.fileType,
            status: file.status,
            createdBy: file.createdBy,
            createdAt: file.createdAt
        }
    }

    static fromPricedParsedFileToModel(file: ParsedPriceFileDto) : ParsePriceFile {
        return {
            id: file.id,
            createdAt: file.createdAt,
            status: file.status,
            createdBy: file.createdBy,
            provider: {
                id: file.provider.id,
                name: file.provider.name
            },
            parsedData: file.parsedData.map((entry) => FileMapper.fromParsedDataDtoToModel(entry))
        }
    }

    static fromParsedDataDtoToModel(entry: ParsedPriceDataDto) : ParsedPriceData {
        return {
            code: entry.code,
            description: entry.description,
            parsedPrice: entry.parsedPrice,
            stockPrice: entry.stockPrice,
            stockItemId: entry.stockItemId,
            isOnStock: entry.isOnStock,
        }
    }

    static fromStockParsedFileToModel(entry: ParsedStockFileDto) : ParsedStockFile {
        return {
            id: entry.id,
            createdAt: entry.createdAt,
            status: entry.status,
            createdBy: entry.createdBy,
            parsedData: entry.parsedData.map((entry) => FileMapper.fromParsedStockDataDtoToModel(entry))
        }
    }

    static fromParsedStockDataDtoToModel(entry: ParsedStockDataDto) : ParsedstockData {
        return {
            code: entry.code,
            description: entry.description,
            ammount: entry.ammount,
            isOnStock: entry.isOnStock,
            stockItemId: entry.stockItemId,
            updatedStockBy: entry.updatedStockBy
        }
    }



}