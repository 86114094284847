import { Injectable } from "@angular/core";
import { PurchaseOrderService } from "../../main-app/services/purchase-order.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import { completePurchaseOrder, completePurchaseOrderSuccess, createPurchaseOrder, downloadPurchaseOrderPdf, downloadPurchaseOrderPdfFailure, downloadPurchaseOrderPdfSuccess, getPurchaseOrdersList, getPurchaseOrdersListFailure, getPurchaseOrdersListSuccess, getSinglePurchaseOrder, getSinglePurchaseOrderFailure, getSinglePurchaseOrderSuccess, processPurchaseOrder, processPurchaseOrderSuccess, updatePurchaseOrder } from "../actions/purchase-order.action";
import { catchError, EMPTY, exhaustMap, map, of } from "rxjs";
import { PurchaseOrderMapper } from "../../common/mappers/purchase-order.mapper";
import { ToastrService } from "ngx-toastr";
import { createSaleOrder } from "../actions/sale-order.action";
import { Store } from "@ngrx/store";

@Injectable({ providedIn: 'root' })
export class PurchaseOrderEffect {

    constructor(
        private actions$: Actions,
        private purchaseOrderService: PurchaseOrderService,
        private router: Router,
        private readonly toastrService: ToastrService,
        private readonly store: Store
    ) {}

    getPurchaseOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getPurchaseOrdersList),
            exhaustMap(action => {
                return this.purchaseOrderService.getPurchaseOrders(action.limit, action.offset).pipe(
                    map(response => {
                        return getPurchaseOrdersListSuccess({
                            orders: response.orders.map(order => PurchaseOrderMapper.fromApiToState(order)),
                            totalOrders: response.totalOrders
                        })
                    }),
                    catchError(error => {
                        return of(getPurchaseOrdersListFailure())
                    }))
                })
        ));

    getPurchaseOrdersFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getPurchaseOrdersListFailure),
            map(() => {
                this.toastrService.warning("Ocurrio un error al cargar las ordenes de compra, por favor intenta de nuevo", "Oopss!");
            })
        ),
        { dispatch: false }
    );

    getSinglePurchaseOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSinglePurchaseOrder),
            exhaustMap(action => {
                return this.purchaseOrderService.getSinglePurchaseOrder(action.id).pipe(
                    map(response => getSinglePurchaseOrderSuccess({
                        order: PurchaseOrderMapper.fromApiToState(response)
                    })),
                    catchError((error) => {
                        if (error.status === 404) {
                            return of(createPurchaseOrder({ }));
                        }
                        return of(getSinglePurchaseOrderFailure());
                    })
                );
            })
        )
    );    

    getSinglePurchaseOrderFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSinglePurchaseOrderFailure),
            map(() => {
                this.toastrService.warning("Ocurrio un error al cargar la orden de compra, por favor intenta de nuevo", "Oopss!");
            })
        ),
        { dispatch: false }
    );

    processPurchaseOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(processPurchaseOrder),
            exhaustMap(action => {
                return this.purchaseOrderService.processPurchaseOrder(action.id).pipe(
                    map((response) => {
                        return processPurchaseOrderSuccess({
                            order: PurchaseOrderMapper.fromApiToState(response)
                        });
                    }),
                    catchError(error => of(getSinglePurchaseOrderFailure()))
                )
            }
        )
    ));

    processPurchaseOrderFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSinglePurchaseOrderFailure),
            map(() => {
                this.toastrService.warning("Ocurrio un error al procesar la orden de compra, por favor intenta de nuevo", "Oopss!");
            })
        ),
        { dispatch: false }
    );

    completePurchaseOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(completePurchaseOrder),
            exhaustMap(action => {
                return this.purchaseOrderService.completePurchaseOrder(action.id).pipe(
                    map((response) => {
                        return completePurchaseOrderSuccess({
                            order: PurchaseOrderMapper.fromApiToState(response)
                        });
                    }),
                    catchError(error => of(getSinglePurchaseOrderFailure()))
                )
            }
        )
    ));

    completePurchaseOrderFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSinglePurchaseOrderFailure),
            map(() => {
                this.toastrService.warning("Ocurrio un error al completar la orden de compra, por favor intenta de nuevo", "Oopss!");
                return EMPTY;
            })
        ),
        { dispatch: false }
    );

    createPurchaseOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createPurchaseOrder),
            exhaustMap(action => {
                return this.purchaseOrderService.createDraftPurchaseOrder(action.fallbackOrder).pipe(
                    map(response => {
                        this.toastrService.success("Orden de compra creada correctamente", "Éxito!");
                        this.store.dispatch(getSinglePurchaseOrder({ id: response.id }));
                        this.router.navigateByUrl(`/app/purchase-orders/${response.id}`);
                        return void 0;
                    }),
                    catchError(error => {
                        this.toastrService.warning("Ocurrió un error al crear la orden de compra, por favor intenta de nuevo", "Oops!");
                        return of(void 0);
                    })
                );
            })
        ), 
        { dispatch: false }
    );


    updatePurchaseOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updatePurchaseOrder),
            exhaustMap(action => {
                return this.purchaseOrderService.updatePurchaseOrder(action.id, action.order).pipe(
                    map((response) => {
                        this.toastrService.success("Orden de compra actualizada correctamente", "Exito!");
                        this.store.dispatch(getSinglePurchaseOrder({ id: response.id }));
                        return void 0;
                    }),
                    catchError(error => {
                        this.toastrService.warning("Ocurrio un error al actualizar la orden de compra, por favor intenta de nuevo", "Oopss!");
                        return EMPTY;
                    })
                )
            }
        )
    ), { dispatch: false });

    getDownloadPdf$ = createEffect(() =>
        this.actions$.pipe(
            ofType(downloadPurchaseOrderPdf),
            exhaustMap(action => {
                return this.purchaseOrderService.getDownloadPdf(action.id).pipe(
                    map(response => downloadPurchaseOrderPdfSuccess(response)),
                    catchError(() => of(downloadPurchaseOrderPdfFailure()))
                );
            })
        )
    );

    downloadPurchaseOrderPdfSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(downloadPurchaseOrderPdfSuccess),
            map(async (action) => {
                const link = document.createElement('a');
                await new Promise((resolve) => {
                    setTimeout(() => {
                        link.href = action.url;
                        link.name = action.fileName;
                        link.target = '_blank';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        resolve(true)
                    }, 3000)
                })
            })
        ),
        { dispatch: false }
    );

}
