import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, exhaustMap, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, of} from "rxjs";
import { getTaskDownloadSignedUrl, getTasks, getTasksFailure, getTasksSuccess, resumeParsePriceFile, resumeParseStockFile } from "../actions/tasks.action";
import { TasksService } from "../../administration/services/tasks.service";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class TasksEffect {

  getTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTasks),
      exhaustMap(action => {
          return this.tasksService.getTasks().pipe(
            map(response => {
               return getTasksSuccess({ tasks: response })
            }),
            catchError(error => {
              return of(getTasksFailure())
            })
          )
        }
      )
    )
  );

  getTasksFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTasksFailure),
      tap(() => {
        this.toastService.warning("Hubo un error al cargar las tareas, por favor intenta de nuevo");
      })
    ),
    { dispatch: false }
  );

  downloadTaskFile$ = createEffect(() => this.actions$.pipe(
    ofType(getTaskDownloadSignedUrl),
    exhaustMap(action => {
      return this.tasksService.getTaskDownloadUrl(action.id).pipe(
        tap(({ url }) => {
          if (url) {
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.download = '';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }),
        catchError(error => {
          return EMPTY;
        })
      );
    })
  ), { dispatch: false });

  downloadTaskFileFailure$ = createEffect(() => this.actions$.pipe(
    ofType(getTaskDownloadSignedUrl),
    tap(() => {
      this.toastService.warning('Hubo un error al descargar el archivo, por favor intenta de nuevo');
    })
  ), { dispatch: false });

  resumeParsePriceFile$ = createEffect(() => this.actions$.pipe(
    ofType(resumeParsePriceFile),
    exhaustMap(action => {
      return this.tasksService.resumeParsePriceFile(action.workflowId, action.request).pipe(
        map(response => {
          return EMPTY;
       }),
       catchError(error => {
         return EMPTY;
       })
      )
    })
  ), { dispatch: false })

  resumeParseStockFile$ = createEffect(() => this.actions$.pipe(
    ofType(resumeParseStockFile),
    exhaustMap(action => {
      return this.tasksService.resumeParseStockFile(action.workflowId, action.request).pipe(
        map(response => {
          return EMPTY;
       }),
       catchError(error => {
         return EMPTY;
       })
      )
    })
  ), { dispatch: false })

  constructor(
    private readonly actions$ : Actions,
    private readonly tasksService: TasksService,
    private readonly toastService: ToastrService
  ) {}

}
