import { Action, ActionReducer } from '@ngrx/store';
import {environment} from "../../../environments/environment";

export function loggerMiddleware(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action: Action) => {
    const result = reducer(state, action);
    if (!environment.production) {
      console.groupCollapsed(action.type);
      console.log('prev state', state);
      console.log('action', action);
      console.log('next state', result);
      console.groupEnd();
    }
    return result;
  };
}
