import {GroupedProduct} from "../../store/states/grouped-products.state";
import {ItemDto} from "../api-dtos/responses/products.dto";
import {ProductsMapper} from "./products.mapper";

export class GroupedProductMapper {

  static fromApiToState(groupedProduct: { tag: string, items: ItemDto[] }): GroupedProduct {
    return {
      tag: groupedProduct.tag,
      products: groupedProduct.items.map(item => ProductsMapper.mapProduct(item))
    }
  }

}