import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { login, logInFailure, logInSuccess } from '../actions/login.actions';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { catchError, exhaustMap, map, concatMap, tap } from 'rxjs/operators';
import {from, of} from 'rxjs';
import {addCompanyInfo, removeCompanyInfo} from '../actions/company.actions';
import {addUserInfo, removeUserInfo} from '../actions/users.actions';
import { Router } from '@angular/router';
import {ToastrService} from "ngx-toastr";
import {HttpRequestHelper} from "../../common/helpers/http-request.helper";
import {clearIndividualProductsList} from "../actions/individual-products.actions";
import {logout, logOutSuccess} from "../actions/logout.actions";
import {clearGroupedProductsList} from "../actions/grouped-products.actions";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class AuthenticationEffect {

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap(action =>
        this.authenticationService.logIn(action.credentials).pipe(
          map(user => logInSuccess({ user })),
          catchError(error => of(logInFailure()))
        )
      )
    ),
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logInSuccess),
      tap(({user}) => HttpRequestHelper.addAccessToken(user.token)),
      concatMap(({ user }) =>
        of(
          addCompanyInfo({ company: user.company }),
          addUserInfo({ user: user.profile, id: user.id })
        )
      ),
      tap(() => this.router.navigate(['/app']))
    )
  );

  loginFailure$ = createEffect(() =>
      this.actions$.pipe(
        ofType(logInFailure),
        tap(() => this.toastr.error("No pudimos validar tus credenciales"))
      ),
    { dispatch: false }
  );

  $logout = createEffect(() =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => HttpRequestHelper.removeAccessToken()),
        tap(() => this.ngbModel.dismissAll()),
        concatMap(() => of(logOutSuccess())),
        tap(() => this.router.navigate(['/authentication/login']))
      ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly ngbModel: NgbModal
  ) {}

}