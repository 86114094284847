import { createReducer, on } from '@ngrx/store';
import { initialTasksState } from '../states/tasks.state';
import { clearTasks, getTasks, getTasksSuccess } from '../actions/tasks.action';

export const tasksReducer = createReducer(
  initialTasksState,
  on(getTasksSuccess, (state, { tasks }) => ({
    ...state, tasks
  })),
  on(clearTasks, (state) => ({
    ...state,
    tasks: initialTasksState.tasks
  })),
);
