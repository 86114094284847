import { createReducer, on } from '@ngrx/store';
import { initialStoreState } from '../states/store.state';
import { clearStoreProducts, getStoreProductsSuccess } from '../actions/store.action';

export const storeReducer = createReducer(
  initialStoreState,
  on(getStoreProductsSuccess, (state, { products, totalProducts }) => ({
    ...state,
    products,
    totalProducts: totalProducts
  })),
  on(clearStoreProducts, (state) => ({
    ...state,
    products: initialStoreState.products
  })),
);
