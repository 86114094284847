
import {ActionReducerMap} from '@ngrx/store';
import {companyInitialState, CompanyState} from "./company.state";
import {initialUserState, UserState} from "./user.state";
import {IndividualProductsState, initialIndividualProductsState, Product} from "./individual-products.state";
import {GroupedProductsState, initialGroupedProductsState} from "./grouped-products.state";
import {companyReducer} from "../reducers/company.reducer";
import {usersReducer} from "../reducers/users.reducer";
import {individualProductsReducer} from "../reducers/individual-products.reducer";
import {groupedProductsReducer} from "../reducers/grouped-products.reducer";
import { FilesState, initialFilesState } from './files.state';
import { filesReducer } from '../reducers/files.reducer';
import { initialProvidersState, ProviderState } from './providers.state';
import { providersReducer } from '../reducers/provider.reducer';
import { initialParsePriceFilesState, ParsePriceFileState } from './parsed-price-file.state';
import { stockParseFileReducer } from '../reducers/parsed-stock-file.reducer';
import { initTableSaleOrderState, SaleOrdersState } from './sale-orders.state';
import { saleOrdersReducer } from '../reducers/sale-orders.reducer';
import { initSaleOrderState, SaleOrderState } from './sale-order.state';
import { saleOrderReducer } from '../reducers/sale-order.reducer';
import { ClientsState, initClientsState } from './clients.state';
import { clientsReducer } from '../reducers/clients.reducer';
import { initialTasksState, TasksState } from './tasks.state';
import { tasksReducer } from '../reducers/tasks.reducer';
import { initialParseStockFilesState, ParseStockFileState } from './parsed-stock-file.state';
import { pricedParseFileReducer } from '../reducers/parsed-price-file.reducer';
import { initialLowStockProducts, LowStockProductState } from './low-stock-products.state';
import { lowStockProductsReducer } from '../reducers/low-stock-products.reducer';
import { DashboardState, initialDashboardState } from './dashboard.state';
import { dashboardReducer } from '../reducers/dashhboard.reducer';
import { initialStoreState, StoreState } from './store.state';
import { storeReducer } from '../reducers/store.reducer';
import { initialPurchaseOrderState, PurchaseOrderState } from './purchase-order.state';
import { purchaseOrderReducer } from '../reducers/purchase-order.reducer';

export interface AppState {
  company: CompanyState,
  user: UserState,
  individualProducts: IndividualProductsState,
  groupedProducts: GroupedProductsState,
  files: FilesState,
  providers: ProviderState,
  parsedPriceFile: ParsePriceFileState,
  parsedStockFile: ParseStockFileState,
  saleOrders: SaleOrdersState,
  saleOrder: SaleOrderState,
  clients: ClientsState,
  tasks: TasksState,
  lowStockProducts: LowStockProductState,
  dashboard: DashboardState,
  store: StoreState,
  purchaseOrders: PurchaseOrderState;
}

export const reducers: ActionReducerMap<AppState> = {
  company: companyReducer,
  user: usersReducer,
  individualProducts: individualProductsReducer,
  groupedProducts: groupedProductsReducer,
  files: filesReducer,
  providers: providersReducer,
  parsedPriceFile: pricedParseFileReducer,
  parsedStockFile: stockParseFileReducer,
  saleOrders: saleOrdersReducer,
  saleOrder: saleOrderReducer,
  clients: clientsReducer,
  tasks: tasksReducer,
  lowStockProducts: lowStockProductsReducer,
  dashboard: dashboardReducer,
  store: storeReducer,
  purchaseOrders: purchaseOrderReducer
};

export const initialState : AppState = {
  company: companyInitialState,
  user: initialUserState,
  individualProducts: initialIndividualProductsState,
  groupedProducts: initialGroupedProductsState,
  files: initialFilesState,
  providers: initialProvidersState,
  parsedPriceFile: initialParsePriceFilesState,
  parsedStockFile: initialParseStockFilesState,
  saleOrders: initTableSaleOrderState,
  saleOrder: initSaleOrderState,
  clients: initClientsState,
  tasks: initialTasksState,
  lowStockProducts: initialLowStockProducts,
  dashboard: initialDashboardState,
  store: initialStoreState,
  purchaseOrders: initialPurchaseOrderState
}